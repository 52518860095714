import React, { FC, useEffect } from 'react'
import MambaLayoutIndex from 'components/layout/MambaLayout/MambaLayoutIndex'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { useDispatch } from 'react-redux'
import { indexPath } from 'components/paths'
import { replace } from 'functions/router'

export const ShellPage: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    console.info('Redirect from shell', indexPath)
    dispatch(replace(indexPath))
  }, [dispatch])

  return (
    <MambaLayoutIndex>
      <DefaultLoader />
    </MambaLayoutIndex>
  )
}

export default ShellPage
